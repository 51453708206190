import styled, {createGlobalStyle} from 'styled-components'
import $ from 'jquery'
import { useEffect, Fragment } from 'react'
import { gsap } from 'gsap'
// import { TimelineMax } from 'gsap/TimelineMax'
// import { Linear } from 'gsap/Linear'
import cloud1 from "../img/cloud-1.png"
import cloud2 from "../img/cloud-2.png"
import cloud3 from "../img/cloud-3.png"
import cloud4 from "../img/cloud-4.png"
import cloud5 from "../img/cloud-5.png"
// export const wh = 672

const timelines = []

const initGsap = (rollingClass, time, width, height, initX, top, initDelay) => {
    width = width*.6
    height = height*.6
    time = time*1.5
    var $scroller = $(`${rollingClass}`)
    var $img = $(`${rollingClass} img`)
    const $wh = $('body').width()
    // gsap.set(`${rollingClass}`, { x: `-${$wh*initX}` })
    gsap.set(`${rollingClass}`, { x: 0 })
    $img.css({ height: `${height}px`, width: `${width}px`, top: `${top}px`})
    $img.fadeIn(100);
  
    /**
     * 
     * @returns 
     */
    function startRolling(init) {
      // if (!timelines[rollingClass]) timelines[rollingClass] = new gsap.timeline({onComplete: timelineComplete, delay: -1});
      console.log(`startRolling`, init)
      // $text.css({width:"100%"});
    //   $scroller.width(width);
    //   $text.css(init ? {width: `100%`} : {width: `calc(100% + 298px)`});
      // timeLine.time(80)
      // timeLine.seek(time/2)
      var width = $scroller.width()
      gsap.to(`${rollingClass}`, { x: -($wh+width), duration: (time), ease: 'none', repeat: -1, delay: initDelay, onRepeat: () => {
        console.log(`onComplete`)
        gsap.set(`${rollingClass}`, { x: 0 })
      }})
      // return timelines[rollingClass];
    }
    
    /**
     * 
     * @returns 
     */
    function resizeCheck(){
      if (!timelines[rollingClass]) return
      var progress = timelines[rollingClass].progress(); //record the progress so that we can match it with the new tween (jump to the same spot)
      timelines[rollingClass].time(0).kill(); //rewind and kill the original tween.
      //time = 5;
      
      startRolling().progress(progress); //create a new tween based on the new size, and jump to the same progress value.
    };
    
    startRolling(true);
    resizeCheck();
    $(window).resize(resizeCheck);
}

const cloudCollection = [
  {
    id: `cloud1`,
    initX: 1.05,
    img: cloud1,
    width: 743,
    height: 329,
    time: 16,
    top: 130,
    delay: 1
  },
  {
    id: `cloud2`,
    initX: .80,
    img: cloud2,
    width: 718,
    height: 318,
    time: 24,
    top: 512,
    delay: 0
  },
  {
    id: `cloud3`,
    initX: .95,
    img: cloud3,
    width: 373,
    height: 120,
    time: 22,
    top: 538,
    delay: 3
  },
  {
    id: `cloud4`,
    initX: .3,
    img: cloud4,
    width: 295,
    height: 98,
    time: 12,
    top: 40,
    delay: 2
  },
  {
    id: `cloud5`,
    initX: .6,
    img: cloud5,
    width: 553,
    height: 248,
    time: 18,
    top: 17,
    delay: 4
  }
]

function Cloud ({ c }) {
  useEffect(() => {
    initGsap(`.${c.id}`, c.time, c.width, c.height, c.initX, c.top, c.delay)
  }, [])

  const StyledDiv = styled.div`
    img.repeater {
      left: calc(100% - ${c.width}px) !important;
    }
  `

  return (
    <StyledDiv className={`rolling ${c.id}`}>
      <img src={c.img}/>
    </StyledDiv>
  )
}

const CloudsContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;

  .rollWrap {
    position: relative;
  }
  .rolling {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    z-index: 2;
    left: 100%;
  }
  .rolling img {
    position: relative;
    display: none;
  }
  img {
    width: 100%;
  }
`

export default function Clouds() {
  return (
      <CloudsContainer>
        {cloudCollection.map(c => (
            <div className={`rollWrap`}>
                <Cloud c={c}/>
            </div>
        ))}
      </CloudsContainer>
  )
}