import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import App from './App';
import Home from './Home';
import Science from './Science';
import reportWebVitals from './reportWebVitals';
import {$,jQuery} from 'jquery';
import styled, {createGlobalStyle} from 'styled-components'
import colors from './theme/colors'
import device from './utils/mediaQueries'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// export for others scripts to use
window.$ = $;
window.jQuery = jQuery;

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => (props.home ? colors.bg : colors.blueBg)};
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    transition: background-color 0.5s;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Staatliches', sans-serif;
    font-weight: 100;
    letter-spacing: 2px;
  }
  h1 {
    font-size: 56px;
  }
  h2 {
    font-size: 48px;
  }
  p {
    line-height: 25px;
    font-size: 14px;
    @media ${device.tablet} {
        font-size: 16px;
    }
  }
`

ReactDOM.render(
  <React.StrictMode>
    <ParallaxProvider>
      <Router>
        <Switch>
          <Route path="/science">
            <Science/>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ParallaxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
