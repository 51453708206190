import styled from "styled-components"
import Logo from './logo'
import device from '../utils/mediaQueries'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import {
    useRouteMatch,
} from "react-router-dom"

const StyledNavigationContainer = styled.div`
    display: flex;
    padding: 20px 0 0;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 20px;
    z-index: 5;

    @media ${device.tablet} {

    }
`

const StyledNavigation = styled.div`
    display: flex;
    justify-content: center;
    width: 100vw;
    
    @media ${device.mobileM} {
        padding: 0 15px;
        
    }
    @media ${device.mobileL} {
        padding: 0 100px;
    }
`

const StyledUl = styled.ul`
    margin: 30px 0 0;
    padding: 0;
    align-items: center;
    width: 40vw;
    
    &.left {
        justify-content: flex-end;
        li {
            text-align: left;
        }
    }
    &.right {
        justify-content: flex-start;
        li {
            text-align: right;
        }
    }

    @media ${device.tablet} {
        display: flex;
    }
`

const StyledLi = styled.li`
    display: block;
    margin: 0 10px 20px;
    text-transform: uppercase;

    a {
        text-decoration: none;
        color: ${props => (props.isHome ? `#000` : `#FFF`)};

        &:visited {
            color: ${props => (props.isHome ? `#000` : `#FFF`)};
        }
    }
    
    @media ${device.tablet} {
        display: inline-flex;
        margin: 0 30px;
    }
`

const Navigation = ({}) => {
    const match = useRouteMatch()
    const isHome = match.path.indexOf('science') === -1
    return (
        <StyledNavigationContainer>
            <StyledNavigation>
                <StyledUl className="left">
                    <StyledLi className="left" isHome={isHome}><Link to="/">Home</Link></StyledLi>
                    <StyledLi className="left" isHome={isHome}><Link to="/science">The Science</Link></StyledLi>
                </StyledUl>
                <Link to="/"><Logo/></Link>
                <StyledUl className="right">
                    <StyledLi className="right" isHome={isHome}><HashLink smooth to="/#locate">Locate</HashLink></StyledLi>
                    <StyledLi className="right" isHome={isHome}><HashLink smooth to="/#contact">Contact</HashLink></StyledLi>
                </StyledUl>
            </StyledNavigation>
        </StyledNavigationContainer>
    )
}

export default Navigation