import styled from 'styled-components';
import logo from "../img/logo.svg";
import logo2 from "../img/logo-white-2.svg";
import { useRouteMatch } from "react-router-dom"


const LogoContainer = styled.div`
    padding: 20px 0 0;
    display: flex;
    justify-content: center;
`

const StyledLogo = styled.div`
    width: 90px;
    height: 90px;
    background-image: ${props => (props.isHome ? `url(${logo})` : `url(${logo2})`)};
    background-size: contain;
    background-repeat: no-repeat;
`

const Logo = ({}) => {
    const match = useRouteMatch()
    const isHome = match.path.indexOf('science') === -1

    return (
        <LogoContainer>
            <StyledLogo isHome={isHome}/>
        </LogoContainer>
    )
}

export default Logo