import { useEffect, useCallback, useMemo, useState, Fragment } from 'react'
import styled from 'styled-components'
import backdrop from '../img/backdrop.svg'
import clockBgLeft from '../img/clock-bg-left.svg'
import clockBgRight from '../img/clock-bg-right.svg'
import arrow from '../img/arrow.svg'
import plus from '../img/plus.svg'
import axios from 'axios'
import countdown from 'countdown'
import device from '../utils/mediaQueries'
import colors from '../theme/colors'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
`

const ClockContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    @media ${device.tablet} {
        flex-direction: row;
        
    }
`

const ClockTitle = styled.h3`
font-family: 'Open Sans', sans-serif;
font-size: 20px;
font-weight: 700;
color: #fff;
line-height: 17px;
height: 32px;
display: flex;
margin: 0 0 14px;
font-size: 14px;

img.arrow {
    width: 43px;
    height: 32px;
    margin: 0 25px 0 0;
}
img.plus {
    width: 40px;
    height: 40px;
    margin: 0 25px 0 0;
}
@media ${device.laptopL} {
    line-height: 32px;
}
`

const TextContainer = styled.div`
display: flex;
`

const TextGroup = styled.div`
margin: 0 10px 0 0;

@media ${device.tablet} {
    margin: 0 20px 0 0;
}
`

const ClockTextTop = styled.h4`
font-family: 'Staatliches', cursive;
font-size: 34px;
margin: 0;
padding: 0;
color: #fff;
line-height: 50px;
font-weight: 100;
letter-spacing: 5px;

@media ${device.tablet} {
    font-size: 38px;
}
@media ${device.laptop} {
    font-size: 60px;
}
`

const ClockTextBottom = styled(ClockTextTop)`
line-height: 77px;
`

const Timeframe = styled.h5`
font-family: 'Open Sans', sans-serif;
font-weight: 600;
font-size: 11px;
text-transform: uppercase;
color: #fff;
margin: 0;

@media ${device.tablet} {
    font-size: 13px;
    text-transform: none;
}
@media ${device.laptop} {
    font-size: 16px;
}
@media ${device.laptopL} {
    font-size: 20px;
}
`

const ClockSection = styled.div`
height: 167px;
padding: 25px;
background-position: right;
background-repeat: no-repeat;
position: relative;
width: 80vw;
max-width: 540px;

@media ${device.tablet} {
    padding: 25px 40px;
    width: 45vw;
}
`


const StyledCountdown = styled(ClockSection)`
background-image: url(${clockBgLeft});
z-index: 5;

@media ${device.tablet} {
    left: 16px;
}
`

const StyledRenewable = styled(ClockSection)`
background-image: url(${clockBgRight});
z-index: 4;

@media ${device.tablet} {
    left: -16px;
}
`

const Countdown = styled.div``
const Countup = styled.div``

const getFeed = () => {
    return axios.get('https://api.climateclock.world/v1/clock').then(res => {
        const modules = res.data.data.modules
        console.log(`res`, modules)
        const deadline = new Date(modules.carbon_deadline_1.timestamp)
        const feed = modules.newsfeed_1.newsfeed.map(n => n.headline).join(' | ') + ' | '

        return {
            modules,
            deadline,
            feed,
        }
    }).catch(err => { // eslint-disable-next-line
        console.log(err)   
    })
}

const CountdownTimer = () => {
    const [feed, setFeed] = useState(null)
    const [countdownRemaining, setCountdownRemaining] = useState(null)
    const deadline = useMemo(() => {
        if (!feed) return false
        const { modules } = feed
        return new Date(modules.carbon_deadline_1.timestamp)
    }, [feed])
    
    useEffect(() => {
        let resizeInterval = 100
        let tickInterval = 250

        const interval = setInterval(() => {
            const now = Date.now()
            setCountdownRemaining(deadline ? countdown(deadline, now, countdown.YEARS | countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS) : null)
        }, tickInterval);

        return () => {
            clearInterval(interval);
        };
    }, [feed])

    useEffect(() => {
        getFeed().then(setFeed)
    }, [])

    return (
        <StyledCountdown>
            <ClockTitle><img className={`arrow`} src={arrow}/>WE MUST ACHIEVE ZERO EMISSIONS BY</ClockTitle>
            <TextContainer>
                { countdownRemaining &&
                    <Fragment>
                        <TextGroup>
                            <ClockTextTop>{countdownRemaining && countdownRemaining.years}</ClockTextTop>
                            <Timeframe>Years</Timeframe>
                        </TextGroup>
                        <TextGroup>
                            <ClockTextTop>{countdownRemaining && countdownRemaining.days}</ClockTextTop>
                            <Timeframe>Days</Timeframe>
                        </TextGroup>
                        <TextGroup>
                            <ClockTextTop>{countdownRemaining && countdownRemaining.hours}:{countdownRemaining && countdownRemaining.minutes}:{countdownRemaining && countdownRemaining.seconds} </ClockTextTop>
                            <Timeframe>Hours, mins, secs</Timeframe>
                        </TextGroup>
                    </Fragment>
                }
            </TextContainer>
        </StyledCountdown>
    )
}

const RenewablePercentage = () => {
    const [renewable, setRenewable] = useState(null)
    const SECONDS_PER_YEAR = 365.25 * 24 * 3600
    const renewPlaces = 9
    const renewStartDate = new Date(Date.UTC(2019, 0, 1, 0, 0, 0))
    const renewStartPct = 10.182522
    const renewIncPerYear = (45 - 26.2)/(2040 - 2019) // Expected rise to 45% by 2040 w/26.2% by 2019

    const renewIncPerSecond = () => {
        return renewIncPerYear / SECONDS_PER_YEAR
    }

    const renewablePercent = () => {
        let tElapsed = Date.now() - renewStartDate.getTime()
        return (renewStartPct + (tElapsed / 1000 * renewIncPerSecond())).toFixed(renewPlaces)
    }

    useEffect(() => {
        let tickInterval = 250

        const interval = setInterval(() => {
            setRenewable(renewablePercent())
        }, tickInterval);

        return () => {
            clearInterval(interval);
        };
    }, [])


    return (
        <StyledRenewable>
            <ClockTitle><img className={`plus`} src={plus}/>THE WORLD’S ENERGY FROM RENEWABLES</ClockTitle>
            <TextContainer>
                <TextGroup>
                    <ClockTextBottom>{renewable && `${renewable.split('.')[0]}.${renewable.split('.')[1]}%`}</ClockTextBottom>
                </TextGroup>
            </TextContainer>
        </StyledRenewable>
    )
}

let tick = 0
const Clock = ({}) => {
    

    return (
        <Container>
            <ClockContainer>
                <CountdownTimer/>
                <RenewablePercentage/>
            </ClockContainer>
        </Container>
    )
}

export default Clock