import { Fragment } from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import colors from '../theme/colors'
import device from '../utils/mediaQueries'
import { SectionContainer } from '../theme/common'
import { Parallax } from 'react-scroll-parallax'

import SectionTwoTopImg from '../img/section-2-top.svg'
import SectionTwoBottomImg from '../img/section-2-bottom.svg'
import SectionTwoLayerTopImg from '../img/section-2-layer.svg'
import SectionTwoLayerBottom1Img from '../img/section-2-layer-bottom-1.svg'
import SectionTwoLayerBottom2Img from '../img/section-2-layer-bottom-2.svg'
import SectionTwoLowerImg from '../img/section-2-lower.svg'
import Tree1 from '../img/section-2-tree-1.svg'
import Tree2 from '../img/section-2-tree-2.svg'
import Kanga from '../img/section-2-kanga.svg'
import arrow from '../img/arrow.svg'

import { Link } from 'react-router-dom'

export const wh = 672
export const Container = styled.div`
  width: 100vw;
  height: auto;
  display: block;
  margin: 0 auto;
  overflow: hidden;
`

const SectionTwoStyled = styled(SectionContainer)`
  position: relative;
  display: flex;
  // height: 100vh;
  h2 {
    color: ${colors.primary};
  }
`

const sectionTwoHeight = 160;
const sectionTwoHeightDesktop = 180;
const sectionTwoBottomHeight = 391;
const SectionTwoTop = styled.div`
    background-image: url(${SectionTwoTopImg});
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: ${sectionTwoHeight}px;
    width: 100%;
    position: absolute;
    top: -${sectionTwoHeight}px;
    z-index: 5;
  
  @media ${device.desktop} {
    height: ${sectionTwoHeightDesktop}px;
    top: -${sectionTwoHeightDesktop}px;
  }
  `

const SectionTwoInner = styled.div`
  background: ${colors.earth};
  width: 100%;
  position: relative;
  z-index: 6;
`
  
const SectionTwoBottom = styled.div`
  background-image: url(${SectionTwoBottomImg});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  height: ${sectionTwoBottomHeight}px;
  width: 100%;
  position: absolute;
  bottom: -${sectionTwoBottomHeight}px;
  z-index: 5;
`

const SectionTwoLower = styled.div`
  background-image: url(${SectionTwoLowerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  max-width: 1176px;
  color: #fff;
  position: relative;
  left: -50px;
  padding: 20px 50px;
  margin: 50px 0 0;
  // text-align: center;
  height: 600px;
  
  h2 {
    color: #fff;
  }
  
  @media ${device.tablet} {
    // padding: 50px 60px;
    left: -100px;
    height: 500px;
  }
  @media ${device.laptop} {
    text-align: left;
    height: 454px;
    padding: 50px 0 0 200px;
    margin: 50px 0 0 -200px;
    left: 0;
    background-position: center;
  }
`

const InnerCountainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 50px;
  
  @media ${device.tablet} {
    padding: 0 100px;
  }

  @media ${device.laptop} {
    p {
      max-width: 700px;
    }
  }
`

const SectionTwoLayerTop = styled.img`
  position: absolute;
  // top: -300px;
  z-index: 3;
  width: 100%;
`

const SectionTwoLayerBottom1 = styled.img`
  position: absolute;
  // bottom: -${sectionTwoBottomHeight + 100}px;
  width: 100%;
  `
  const SectionTwoLayerBottom2 = styled.img`
  position: absolute;
  // bottom: -${sectionTwoBottomHeight + 300}px;
  z-index: 2;
  width: 100%;
  `
  
  const SectionTwoGlobal = createGlobalStyle`
  .section-two-layer-top {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    
    .laxx1 {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      margin:0;
      
      .parallax-inner {
        height: 331px;
      }
    }
  }
  .section-two-layer-bottom-1 {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    z-index: 3;

    .laxx2 {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      margin:0;

      .parallax-inner {
        height: 500px;
      }
    }
  }
  .section-two-layer-bottom-2 {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    z-index: 3;

    .laxx3 {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      margin:0;

      .parallax-inner {
        height: 500px;
      }
    }
  }
`

const numberDims = 35;
const SectionTwoOl = styled.ol`
  padding: 0;
  margin: 0;
`
const SectionTwoLi = styled.li`
  list-style: none;
  margin: 0 0 10px;
  text-transform: uppercase;
  line-height: 29px;
  display: flex;
  font-size: 14px;
  
  .number {
    width: ${numberDims}px;
    height: ${numberDims}px;
    border-radius: ${numberDims/2}px;
    border: 2px solid #fff;
    display: inline-block;
    margin: 0 10px 0 0;
    text-align: center;
    display: inline-table;
    @media ${device.tablet} {
      margin: 0 15px 0 0;
    }
  }
  
  @media ${device.tablet} {
    font-size: 16px;
    line-height: 31px;
  }
`

const SectionTwoImages = styled.div`
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  @media ${device.laptop} {
    width: 50vw;
  }

  img {
    position: absolute;

    &.tree1 {
      top: -175px;
      width: 150px;
      left: 30px;
      opacity: .9;
      @media ${device.laptop} {
        top: -50px;
        opacity: 1;
      }
    }
    &.tree2 {
      top: 100px;
      right: -250px;
      opacity: .2;

      @media ${device.laptop} {
        right: -100px;
        opacity: .6;
      }
      @media ${device.laptopL} {
        right: -20px;
        opacity: 1;
      }
    }
    &.kanga {
      display: none;
      
      @media ${device.laptop} {
        display: block;
        top: 600px;
        left: 200px;
      }
    }
  }
`

const StyledScienceCta = styled.div`
margin: 30px 0 0;
a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  
  &:visited {
    text-decoration: none;
  }

  img {
    height: 20px;
    position: relative;
    top: 4px;
  }
}
`

const StyledAnchor = styled.a`
  color: ${colors.primary};
`

export default function SectionTwo() {
    return (
      <Fragment>
        <SectionTwoGlobal/>
        <SectionTwoStyled>
            <SectionTwoImages>
              <img src={Tree1} className="tree1"/>
              <img src={Tree2} className="tree2"/>
              <img src={Kanga} className="kanga"/>
            </SectionTwoImages>
            <div className="section-two-layer-top">
              <Parallax className="laxx1" y={[0, -140]} tagOuter="figure">
                <SectionTwoLayerTop src={SectionTwoLayerTopImg} />
              </Parallax>
            </div>
            <SectionTwoTop/>
            <SectionTwoInner>
              <InnerCountainer>
                <h2>WHAT IS THE CLIMATE CLOCK?</h2>
                <p>Welcome to the Climate Clock community. We are passionate citizens, scientists, artists and activists, united to make our planet a better place to live. Climate change is the defining challenge of our time, requiring local solutions to this global problem. We are focusing the world's attention on the <em>critical time window</em> for climate action by installing Climate Clocks around the world. </p>
                <p>A Climate Clock is a tool to encourage more people to talk about climate change and hold not only the decision-makers but all of us accountable for our actions. Every day, hour and second counts. The Climate Clock is an open-source project. We welcome you to be part of the journey. <br/>
                Learn more at <StyledAnchor href="http://www.climateclock.world" target="_new">www.climateclock.world</StyledAnchor></p>
                <h2>About Climate Clock Australia</h2>
                <p>Climate Clock Australia is an inclusive community of ordinary Australians committed to uniting climate change action and advocacy. We want to live in a country that leads by example, supports vulnerable communities, appropriately responds to science and promotes regenerative living. Everyone can do their bit. </p>
                <p>We are also on a mission to install Australia’s first permanent monumental Climate Clock. When erected, the clock becomes part of a global network to inspire individual and industry action. The Climate Clock is a daily reminder for our leaders to equitably transition communities to renewable energies and rigorously promote regenerative living. To assist us, please contact Climate Clock Australia at climateclockaus@gmail.com.</p>
                <p>Our efforts are guided by science and the urgency we feel for real and effective change. The faster we transition to renewable energies, the more time we will have to fix past mistakes, protect the places we love and care for and live in a healthier, happier, safer and more prosperous country.</p>
                <SectionTwoLower>
                  <h2>CLIMATE CLOCK AUSTRALIA GOALS</h2>
                  <SectionTwoOl>
                    <SectionTwoLi><span class="number">1</span>Popularise climate change</SectionTwoLi>
                    <SectionTwoLi><span class="number">2</span>Empower individuals to be the change</SectionTwoLi>
                    <SectionTwoLi><span class="number">3</span>INFLUENCE THE LEADERS TO MAKE THE CHANGE</SectionTwoLi>
                  </SectionTwoOl>
                  <StyledScienceCta><Link to="/science" onClick={() => window.scrollTo(0,0)}>Learn about the science <img className={`arrow`} src={arrow}/></Link></StyledScienceCta>
                </SectionTwoLower>
              </InnerCountainer>
            </SectionTwoInner>
            <SectionTwoBottom/>
            <div className="section-two-layer-bottom-1">
              <Parallax className="laxx2" y={[-50, 30]} tagOuter="figure">
                <SectionTwoLayerBottom1 src={SectionTwoLayerBottom1Img}/>
              </Parallax>
            </div>
            <div className="section-two-layer-bottom-2">
              <Parallax className="laxx3" y={[-90, 50]} tagOuter="figure">
                <SectionTwoLayerBottom2 src={SectionTwoLayerBottom2Img}/>
              </Parallax>
            </div>
        </SectionTwoStyled>
      </Fragment>
    )
}