import './App.css'
import { Fragment } from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import { GlobalStyle } from './index'
import {
  Link,
  useLocation,
  useRouteMatch,
} from "react-router-dom"


export const wh = 672
// const T = TimelineMax; // eslint-disable-line

export const StyledContainer = styled.div`
  height: auto;
  display: block;
  margin: 0 auto;
  overflow: hidden;
`

function Container({children}) {
  const match = useRouteMatch()
  const isHome = match.path.indexOf('science') === -1

  return (
    <Fragment>
        <GlobalStyle home={isHome}/>
        <StyledContainer>
            {children}
        </StyledContainer>
    </Fragment>
  );
}

export default Container