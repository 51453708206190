const colors = {
    bg: `#FEEEE1`,
    text: `#000`,
    primary: `#03749C`,
    orange: `#F97304`,
    earth: `#FAB581`,
    blueBg: `#03749C`,
  }
  

export default colors