import './App.css'
import styled, {createGlobalStyle} from 'styled-components'
import colors from './theme/colors'
import { SectionContainer } from './theme/common'
import SectionOne from './components/sectionOne'
import SectionTwo from './components/sectionTwo'
import IconLocator from './img/icon-locator.svg'
import StoryboxLogo from './img/storybox-logo.png'
import LogoWhite from './img/logo-white.svg'
import device from './utils/mediaQueries'
import {
  Link,
  useLocation,
  useRouteMatch,
} from "react-router-dom";


export const wh = 672
// const T = TimelineMax; // eslint-disable-line



export const Container = styled.div`
  height: auto;
  display: block;
  margin: 0 auto;
  overflow: hidden;
`

const SectionThree = styled(SectionContainer)`
  min-height: 100vh;
  background: #7EB8CD;
  padding: 650px 0 0;
  
  h2 {
    color: #fff;
    width: 100%;
    display: block;
    text-align: center;
  }
  p {
    color: #fff;
    max-width: 600px;
    text-align: center;
    margin: 0 0 30px;
  }
`


const InnerCountainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${device.tablet} {
    padding: 0 100px;
  }
`

const LocationComp = styled.span`
  line-height: 41px;
  color: #fff;
  text-transform: uppercase;
  display: inline-flex;
  line-height: 41px;
  margin: 0 0 30px;

  ::before {
    content: "";
    position: relative;
    width: 26px;
    height: 41px;
    display: inline-block;
    margin: 0 10px 0 0;
    background-image: url(${IconLocator});
    background-size: 26px 41px;
    background-repeat: no-repeat;
  }
`

const ExternalAnchor = styled.a`
  background: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  color: ${colors.primary};
  margin: 20px 0 0;
`

const LogoBottom = styled.img`
  margin: 30px 0;
`

function App() {
  const match = useRouteMatch()
  console.log(`match`, match)

  return (
    <Container>
      <SectionOne/>
      <SectionTwo/>
      <SectionThree>
        <InnerCountainer>
          <h2>CLIMATE CLOCKS NEAR YOU</h2>
          <LocationComp>Darling Harbour, NSW</LocationComp>
          <img src={StoryboxLogo}/>
          <h2>LET'S DO THIS</h2>
          <p>If you would like to assist us in installing a permanent Climate Clock in Australia, or wish to show your support, please get in touch via:</p>
          <ExternalAnchor>climateclockaus@gmail.com</ExternalAnchor>
          <ExternalAnchor>@CLIMATECLOCKAUSTRALIA</ExternalAnchor>
          <LogoBottom src={LogoWhite}/>
        </InnerCountainer>
      </SectionThree>
    </Container>
  );
}

export default App