import Navigation from './navigation'
import styled, {createGlobalStyle} from 'styled-components'
import Clock from "./Clock"
import Clouds from "./Clouds"
import { SectionContainer } from '../theme/common'
import Birds from '../img/birds.svg'
import device from '../utils/mediaQueries'

const StyledSectionOne = styled(SectionContainer)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  img.birds {
    position: absolute;
    top: 176px;
    width: 47px;
    
    @media ${device.mobileL} {
        top: 189px;
        width: 94px;
    }
    @media ${device.tablet} {
        top: 198px;
        width: 145px;
    }
  }
`

export default function SectionOne() {
    return (
        <StyledSectionOne>
            <img src={Birds} className="birds"/>
            <Navigation/>
            <Clock/>
            <Clouds/>
        </StyledSectionOne>
    )
}