import './App.css'
import { Fragment } from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import { GlobalStyle } from './index'
import {
  Link,
  useLocation,
  useRouteMatch,
} from "react-router-dom"
import Container from './Container'
import { SectionContainer } from './theme/common'
import Navigation from './components/navigation'
import ScienceBackground from './img/science-background.svg'
import { Footer } from './Home'
import colors from './theme/colors'

const StyledScience = styled(SectionContainer)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; 
  padding: 0 50px;
`

const StyledScienceBackground = styled.div`
  width: 120%;
  height: 100vh;
  background-image: url(${ScienceBackground});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
  position: absolute;
  top: 0;
  left: -10%;
`

const StyledContent = styled.div`
  width: 100%;
  max-width: 773px;
  color: #fff;
  z-index: 3;
  text-align: center;
`

const StyledH1 = styled.h1`
margin: 30vh 0 30px;
`

const StyledAnchor = styled.a`
color: ${colors.orange}
`

export const wh = 672
// const T = TimelineMax; // eslint-disable-line

function Science() {
  const match = useRouteMatch()
  console.log(`match`, match)

  return (
    <Container>
      <StyledScience>
        <StyledScienceBackground/>
        <Navigation/>
        <StyledContent>
          <StyledH1>The science</StyledH1>
          <p>Our climate is changing at an unprecedented rate and it is affecting all life on earth. This dramatic change is largely caused by us, humans. </p>
          <p>As we have known for decades, a major cause of climate change is unprecedented levels of greenhouse gases in our atmosphere. Climate change threatens everything we love and care about - our biggest cities, regional towns, forests, oceans, our jobs, sports we love, wildlife, food and our country’s economic health. </p>
          <p>This is why we have a carbon budget. If we exceed this budget, global temperatures will rise. Scientists predict that we have limited time before the world will warm by 1.5°C. If our planet does warm to this extent (or more) above pre-industrial levels, we will live in a more challenging, hostile and expensive world. This is our deadline.</p>
          <p>Communities worldwide are switching to renewable energy to live and prosper. We want more of our lives to be powered by renewable sources. The faster we transition to renewable energy, the more regenerative our society will be and the greater chance we have at turning this deadline into a lifeline!</p>
          <p>To learn more visit the Intergovernmental Panel on Climate Change (IPCC) at <StyledAnchor href="http://www.ipcc.ch/" target="_new">www.ipcc.ch/</StyledAnchor></p>
        </StyledContent>
        <Footer/>
      </StyledScience>
    </Container>
  );
}

export default Science